import axios from 'axios'
import {map} from 'lodash'
import {
  CompanyEnum,
  GetBankListQueryData,
  ListCompaniesByTypeQueryData,
  ListCompaniesByTypeQueryVariables,
} from './_models'

const MAIN_USERS_ENDPOINT = process.env.REACT_APP_USER_API_ENDPOINT ?? ''

const getListBank = async () => {
  const response = await axios.post<GetBankListQueryData>(`${MAIN_USERS_ENDPOINT}/users/graphql`, {
    query: `
          query {
            getListBank
          }
        `,
  })
  return response
}
const listCompaniesByType = async ({
  bankCode,
  district,
  province,
}: ListCompaniesByTypeQueryVariables) => {
  const response = await axios.post<ListCompaniesByTypeQueryData>(
    `${MAIN_USERS_ENDPOINT}/users/graphql`,
    {
      query: `
    query listCompaniesByType ($bankCode: String, $district: String, $province: String, $limit: Int, $service: String, $type: CompanyEnum!) {
      listCompaniesByType(bankCode: $bankCode, district: $district, province: $province, limit: $limit, service: $service, type: $type) {
        companies {
          id
          name
        }
      }
    }
    `,
      variables: {
        bankCode,
        district,
        province,
        limit: 1000,
        service: 'b2bRealEstate',
        type: CompanyEnum.bank,
      },
    }
  )
  return map(response.data.data?.listCompaniesByType?.companies, (company) => ({
    label: company?.name,
    value: company?.id,
  }))
}


export {getListBank, listCompaniesByType}
