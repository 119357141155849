/* eslint-disable react/jsx-no-target-blank */
import {Fragment} from 'react'
import {MAIN_PATHNAME} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => (
  <Fragment>
    <SidebarMenuItem
      to={`/${MAIN_PATHNAME}`}
      title='Quản lý yêu cầu vay vốn ngân hàng'
      icon='/media/biha/icons/svg/fluent_text-bullet-list-square-toolbox-20-filled.svg'
    />
  </Fragment>
)

export {SidebarMenuMain}
