import {FormikErrors} from 'formik'
import {filter, forEach, includes, isEqual, isNil, keys, replace, toLower, toString} from 'lodash'
import {Module} from '../../app/modules/auth'
import {RouterProps} from '../layout/components/header/header-menus/routers'

const removeVietnameseAccents = (text: string | null | undefined) =>
  isNil(text)
    ? ''
    : replace(replace(toLower(text.normalize('NFD')), /đ/g, 'd'), /[\u0300-\u036f]/g, '')

const formatFileSize = (bytes: number, decimalPoint?: number) => {
  if (isEqual(bytes, 0)) return '0 Bytes'
  let k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const getFieldErrorNames = <T extends any>(formikErrors: FormikErrors<T>) => {
  const transformObjectToDotNotation = (object: any, prefix = '', result: string[] = []) => {
    forEach(keys(object), (key) => {
      const value = object[key]
      if (!value) return
      const nextKey = prefix ? `${prefix}.${key}` : key
      if (isEqual(typeof value, 'object')) {
        transformObjectToDotNotation(value, nextKey, result)
      } else {
        result.push(nextKey)
      }
    })
    return result
  }
  return transformObjectToDotNotation(formikErrors)
}

const numberWithCommas = (value?: string | number | null) => {
  if (!value) return ''
  value = toString(value)
  var pattern = /(\d)(?=(\d{3})+(?!\d))/g
  value = value.replace(pattern, '$1,')
  return value
}
const filterRoutersAppByModule = (routesApp: RouterProps[], modules?: Module[]) => {
  return filter(
    routesApp,
    (route) => (route.module && includes(modules, route.module)) || !route.module
  )
}

export {
  getFieldErrorNames,
  numberWithCommas,
  formatFileSize,
  removeVietnameseAccents,
  filterRoutersAppByModule,
}
