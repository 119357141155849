import { AxiosResponse } from "axios";
import { createContext, FC, useContext } from "react";
import { WithChildren } from "../../../../_biha/helpers";
import { QueryResponseProvider, useQueryResponseData } from "./core/QueryResponseProvider";
import { GetBankListQueryData } from "./core/_models";

interface BankContextProps {
    bank?: AxiosResponse<GetBankListQueryData>
  }

const BankListContext = createContext<BankContextProps>({})


const BankListWrapper: FC<WithChildren> = ({children}) => {
    const bank = useQueryResponseData()
    return <BankListContext.Provider value={{bank}}>{children}</BankListContext.Provider>
  }

  const BankListProvider: FC<WithChildren> = ({children}) => {
  return (
    <QueryResponseProvider>
      <BankListWrapper>{children}</BankListWrapper>
    </QueryResponseProvider>
  )
}
const useBankList = () => useContext(BankListContext)

export { BankListProvider, useBankList }

