import { AxiosResponse } from 'axios'
import {FC, useContext} from 'react'
import {useQuery} from 'react-query'
import {
  createPaginationResponseContext,
  initialQueryResponse,
  QUERIES,
  WithChildren,
} from '../../../../../_biha/helpers'
import {GetBankListQueryData} from './_models'
import {getListBank} from './_requests'

const QueryResponseContext =
  createPaginationResponseContext<AxiosResponse<GetBankListQueryData>>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.BANK_LIST}`,
    () => {
      return getListBank()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
    const {response} = useQueryResponse()
  
    if (!response) {
      return undefined
    }
    return response || undefined
  }

  const useQueryResponseLoading = (): boolean => {
    const {isLoading} = useQueryResponse()
    return isLoading
  }

export {QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading}
